import React from "react";
import { Box, Typography } from "@material-ui/core";
import EgPageTitle from "../components/EgPageTitle";
import { makeStyles } from "@material-ui/core";
import { globalStyles } from "../styles/global.styles";
import { borders } from "@material-ui/system";

const useStyles = makeStyles((theme) => ({
  boxcontent: {
    border: "1px solid lightgrey",
    padding: "8px",
    width: "98%",
    textAlign: "justify",
  },
}));

const Contact = () => {
  const classes = useStyles();
  const globalClasses = globalStyles();

  return (
    <div className={globalClasses.drawerPadding}>
      <EgPageTitle title="*About EncryptGig"> </EgPageTitle>
      <Box className={classes.boxcontent}>
        <Typography>
        EncryptGig is a team of security experts, who are on a mission to develop powerful, scalable, cost-effective, 
        and yet simple encryption product for every organization and individual's need. Our algorithm is designed on 
        the stateless architecture, which makes it effortlessly scalable in both on-premises and various clouds. 
        Further, every time you trigger the encryptions, we rotate the key, hence providing you the most secured way 
        to protect your data. Plus, forget the traditional pain to remember and maintain your private keys and infrastructure. 
        Since all the crypto operations will be performed in the backend with three layers of encryption automatically on your 
        local machine without you to remember keys. Finally, with EncryptGig, all organizations and individuals can scale up their 
        data protection ability to any extent without worrying about any security bottlenecks as our product comes with the best in-build 
        security features in the industry. We're truly excited to embark on your critical encryption journey with us!
          <Typography>
            <h4>
              {" "}
              <p>
                {" "}
                For demo sales, inquiry, etc. write us at{" "}
                <a href="mailto: contact@encryptgig.com" target="_blank">
                  {" "}
                  contact@encryptgig.com
                </a>
              </p>{" "}
            </h4>
          </Typography>
        </Typography>
      </Box>
    </div>
  );
};
export default Contact;
